import { PrismicImage, PrismicRichText, PrismicPageDefinition } from "./types";

export type TypeSeeLagoonInActionVideo = {
  youtube_video_id: string;
  video_duration: string;
  name: string;
};

export type TypeSeeLagoonInActionPage = {
  preHeader: string;
  subheader: PrismicRichText;
  page_title: string;
  header: string;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;

  videos: Array<TypeSeeLagoonInActionVideo>;
};

export type TypeSeeLagoonInActionPageQueryResult = {
  allPrismicSeeLagoonInAction: {
      edges: Array<{
        node: {data: TypeSeeLagoonInActionPage};
      }>;
  };
};

export const getPage = (data: TypeSeeLagoonInActionPageQueryResult): TypeSeeLagoonInActionPage => {
  return data.allPrismicSeeLagoonInAction.edges[0].node.data;
}
