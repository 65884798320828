import {graphql, StaticQuery} from "gatsby";
import React, {useState} from 'react';
import './see-lagoon-in-action.scss';
import {
    getPage,
    TypeSeeLagoonInActionPageQueryResult,
    TypeSeeLagoonInActionVideo
} from "../../utils/queries/see_lagoon_in_action";
import {PageHeaderStandard} from "../../components/page-header/page-header";
import classNames from 'classnames';
import {PageTitle} from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from '../../components/layout';
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
    {
        allPrismicSeeLagoonInAction {
          edges {
            node {
              data {
                page_meta_description
                page_meta_thumbnail {
                  alt
                  copyright
                  url
                  thumbnails
                }
                videos {
                  youtube_video_id
                  video_duration
                  name
                }
                subheader {
                  html
                  text
                  raw
                }
                pre_header
                page_title
                header
              }
            }
          }
        }
      }
      `;

const VideoPlayer = ({ youtubeId }: { youtubeId: string }) => {
    const url = `https://www.youtube-nocookie.com/embed/${youtubeId}?autoplay=0&fs=1&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0`;
    return (
        <iframe
            frameBorder="0"
            scrolling="no"
            marginHeight={0}
            marginWidth={0}
            style={{ width: `100%`, height: '445px', marginBottom: '20px' }}
            src={url} />
    );
};

const VideoThumbnail = ({ video, active, onClick }: { onClick: () => void, active: boolean, video: TypeSeeLagoonInActionVideo }) => {
    const thumbnailUrl = `http://i3.ytimg.com/vi/${video.youtube_video_id}/hqdefault.jpg`;
    const videoClasses = classNames('video', {
        active
    });
    return (
        <div onClick={onClick} className={videoClasses} style={{ backgroundImage: `url('${thumbnailUrl}')` }}>
            <div className="layover">
                <div className="layover-inner">
                    <div className="play-icon">
                        <svg width="32" height="32" viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg" className="sk22k _2quD3">
                            <g fill="#FFF" fill-rule="evenodd">
                                <path d="M28 53c13.807 0 25-11.193 25-25S41.807 3 28 3 3 14.193 3 28s11.193 25 25 25zm0 3C12.536 56 0 43.464 0 28S12.536 0 28 0s28 12.536 28 28-12.536 28-28 28z"></path>
                                <path d="M37.232 27.91L22 36.82V19z"></path>
                            </g>
                        </svg>
                    </div>
                    {/* <div className="name">{video.name}</div> */}
                    <div className="duration">{video.video_duration}</div>
                </div>
            </div>
        </div>
    );
}

const SeeLagoonInactionPage = ({ data, location }: { location: Location, data: TypeSeeLagoonInActionPageQueryResult }) => {
    const result = usePreviewData(getPage(data), 'prismicSeeLagoonInAction', true);

    const [activeVideoIndex, setActiveVideoIndex] = useState(0);
    const activeVideo = result.videos[activeVideoIndex];

    return (
        <AmazeePage location={location}>
            <div id="see-lagoon-in-action">
                <PageTitle title={result.header} />
                <PageHeaderStandard header={result.header} preHeader={result.preHeader} subheader={result.subheader} withSiteName={true} />
                <AmazeeIoMetadata title={result.header} page_meta_description={result.page_meta_description} page_meta_thumbnail={result.page_meta_thumbnail} location={location} />

                <section className="videos">
                    <div className="container">

                        <div className="row justify-center">
                            <div className="col-12 col-md-8 col-md-7">
                                <VideoPlayer youtubeId={activeVideo.youtube_video_id} />

                                <div className="row">
                                    {result.videos.map((video, index) => (
                                        <div className="col-6 col-md-3" key={index}>
                                            <VideoThumbnail onClick={() => setActiveVideoIndex(index)} video={video} active={index === activeVideoIndex} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </AmazeePage>
    );
};

const StaticSeeLagoonInactionPage: React.FC<{ location: Location }> = ({ location }) => {
    return (
        <StaticQuery
            query={query}
            render={((data) => <SeeLagoonInactionPage location={location} data={data} />)}
        />
    )
}
export default StaticSeeLagoonInactionPage;
